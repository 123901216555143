import {DateService} from '../../../../../../services/util/DateService';
import {getTemplateStopTypeString} from '../../../../../../services/enums/TemplateStopType';

const Columns = {
  internalCustomerId: {
    name: 'internalCustomerId',
    header: ({t}) => t('internalCustomerId'),
    body: ({customer}) => customer.id
  },
  streetAndNumber: {
    name: 'streetAndNumber',
    header: ({t}) => t('streetAndNumber'),
    body: ({customer}) => customer.address?.streetAndNumber || '-'
  },
  zipcode: {
    name: 'zipcode',
    header: ({t}) => t('zipcode'),
    body: ({customer}) => customer.address?.zipcode || '-'
  },
  // This column is only available in TemplateStopMode since it requires the templateStop to be present on the stop on the template
  deliveryWindow: {
    name: 'deliveryWindow',
    header: ({t}) => t('deliveryWindow'),
    body: ({
      customer,
      customerIdToTemplateStopDict
    }) => DateService.displayTime(customerIdToTemplateStopDict[customer.id]?.deliveryWindow?.from) + ' - ' + DateService.displayTime(customerIdToTemplateStopDict[customer.id]?.deliveryWindow?.to)
  },
  // This column is only available in TemplateStopMode since it requires the templateStop to be present on the stop on the template
  stopType: {
    name: 'stopType',
    header: ({t}) => t('stopType'),
    body: ({
      customer,
      customerIdToTemplateStopDict,
      t
    }) => t(getTemplateStopTypeString(customerIdToTemplateStopDict[customer.id]?.stopType)) || '-'
  },
  // This column is only available in TemplateStopMode since it requires the templateStop to be present on the stop on the template
  name: {
    name: 'name',
    header: ({t}) => t('name'),
    body: ({
      customer,
      customerIdToTemplateStopDict,
      t
    }) => t(customerIdToTemplateStopDict[customer.id]?.name) || '-'
  },
}

export default Columns;