export default theme => ({
    root: {},
    materialIcon: {
      marginBottom: '-3px',
      width: '24px',
      height: '24px',
    },
    legendWrapper: {
      float: 'right'
    }
  });