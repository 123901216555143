import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, Grid, Typography, withStyles } from '@material-ui/core';
import styles from './styles';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { Cancel as CancelIcon, Save as SaveIcon } from '@material-ui/icons';
import CustomersAddTable from './CustomersAddTable';
import { AddCustomersToTemplateStopTourMap } from 'components';
import { onCloseWithBackdropCurry } from 'services/util/helperFuncs';
import { displayModes } from 'services/enums/displayModes';

function AddCustomersDialogTemplateStop(props) {

  const {
    classes,
    t,
    dialogState,
    handleCancel,
    handleClose,
    customers,
    microHubs,
    templates,
    selectedTemplate,
    displayMode,
    customerIdToTemplateStopDict
  } = props;
  const [customersToAdd, setCustomersToAdd] = useState([]);
  const [customersInternal, setCustomersInternal] = useState([]);
  const [displayAllTemplates, setDisplayAllTemplates] = useState(true);

  useEffect(() => {
    const templateStopCustomerIds = templates.map(t => t.stops.map(s => s.customer.internalCustomerId)).flat();
    const customersNotOnTemplates = customers
      .filter(customer => !selectedTemplate || customer.shipper === selectedTemplate.shipper) // Filter customers for right shipper
      .filter(customer => !templateStopCustomerIds.includes(customer.id)); // Filter customers for selected Template
    setCustomersInternal(customersNotOnTemplates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers, selectedTemplate, displayAllTemplates]);

  const addOrRemoveCustomer = (customer) => {
    if (customersInternal.findIndex(c => c.id === customer.id) >= 0) {
      const newCustomersToAdd = JSON.parse(JSON.stringify(customersToAdd));
      const index = newCustomersToAdd.findIndex(presentCustomer => presentCustomer.id === customer.id);
      if (index >= 0) {
        newCustomersToAdd.splice(index, 1);
      } else {
        newCustomersToAdd.push(customer);
      }
      setCustomersToAdd(newCustomersToAdd);
    }
  };

  const handleOk = () => {
    // never autoOrder for template stops
    handleClose(customersToAdd, selectedTemplate, false);
    setCustomersToAdd([]);
  };

  const handleNotOk = () => {
    handleCancel();
    setCustomersToAdd([]);
  };

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      fullWidth
      maxWidth={'xl'}
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={dialogState.open}
    >
      <div
        className={classes.headerContainer}
        style={selectedTemplate ? { backgroundColor: selectedTemplate.color } : {}}
      >
        <Typography><strong>{`${t('customerAdd')} ${selectedTemplate ? `${t('toTourTemplate')} ${selectedTemplate.id}` : ''}`}</strong></Typography>
        <Typography><strong>{`${t('tourTemplateName')}: ${selectedTemplate ? selectedTemplate.templateName : '-'}`}</strong></Typography>
        <Typography><strong>{`${t('tourTemplateCustomers')}: ${selectedTemplate?.stops ? selectedTemplate.stops.length + customersToAdd.length : '-'}`}</strong></Typography>
      </div>
      <DialogContent>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            lg={7}
            sm={12}
            xl={7}
            xs={12}
          >
            <AddCustomersToTemplateStopTourMap
              addedCustomers={customersToAdd}
              className={classes.map}
              customers={customersInternal ? customersInternal.filter(customer => customersToAdd.findIndex(c => c.id === customer.id) < 0) : []}
              microHubs={microHubs}
              onSelectCustomer={addOrRemoveCustomer}
              selectedTemplate={selectedTemplate}
              customerIdToTemplateStopDict={customerIdToTemplateStopDict}
              displayAllTemplates={displayAllTemplates}
              setDisplayAllTemplates={setDisplayAllTemplates}
              templates={displayAllTemplates ? templates : [selectedTemplate]}
            />
          </Grid>
          <Grid
            item
            lg={5}
            sm={12}
            xl={5}
            xs={12}
          >
            <CustomersAddTable
              addOrRemoveCustomer={addOrRemoveCustomer}
              className={classes.table}
              customers={customersInternal}
              customersToAdd={customersToAdd}
              selectedTemplate={selectedTemplate}
              displayMode={displayMode}
              customerIdToTemplateStopDict={customerIdToTemplateStopDict}
            />
          </Grid>
        </Grid>

      </DialogContent>
      <DialogActions>
        <Button
          color="default"
          onClick={handleNotOk}
        >
          <CancelIcon />&nbsp;{t('dialogCancel')}
        </Button>
        <Button
          color="primary"
          disabled={!customersToAdd || customersToAdd.length === 0}
          onClick={handleOk}
        >
          <SaveIcon />&nbsp;{t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddCustomersDialogTemplateStop.propTypes = {
  classes: PropTypes.object.isRequired,
  customers: PropTypes.array.isRequired,
  dialogState: PropTypes.object.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  microHubs: PropTypes.array.isRequired,
  selectedTemplate: PropTypes.object,
  t: PropTypes.func.isRequired,
  customerIdToTemplateStopDict: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withTranslation())(AddCustomersDialogTemplateStop);
