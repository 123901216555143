import React from 'react'
import { roundCapacityValues } from '../../../../../../services/util/helperFuncs';
import { NoCapacitiesAvailableWarning } from '../../index';

const Columns = {
  internalCustomerId: {
    name: 'internalCustomerId',
    header: ({ t }) => t('internalCustomerId'),
    body: ({ customer }) => customer.id
  },
  streetAndNumber: {
    name: 'streetAndNumber',
    header: ({ t }) => t('streetAndNumber'),
    body: ({ customer }) => customer.address?.streetAndNumber || '-'
  },
  zipcode: {
    name: 'zipcode',
    header: ({ t }) => t('zipcode'),
    body: ({ customer }) => customer.address?.zipcode || '-'
  },
  boxAmount: {
    name: 'boxAmount',
    header: ({ t }) => t('boxAmount'),
    body: ({ customerCapacities }) => customerCapacities
      ? roundCapacityValues(customerCapacities?.boxAmount)
      : <NoCapacitiesAvailableWarning />
  },
  weight: {
    name: 'weight',
    header: ({ t }) => t('weight'),
    body: ({ customerCapacities }) => customerCapacities
      ? roundCapacityValues(customerCapacities?.weight)
      : <NoCapacitiesAvailableWarning />
  },
  volume: {
    name: 'volume',
    header: ({ t }) => t('volume'),
    body: ({ customerCapacities }) => customerCapacities
      ? roundCapacityValues(customerCapacities?.volume)
      : <NoCapacitiesAvailableWarning />
  },
}

export default Columns;