import { Checkbox, FormControlLabel } from '@material-ui/core';
import { withTranslation } from 'react-i18next';
import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Map, TileLayer } from 'react-leaflet';
import { MapControls, MicroHubMarker, OverlappingMarkerCluster, StopMarkerCluster, TourMarkerCluster } from '../../MapComponents';
import * as L from 'leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';
import {
  filterMicroHubsForCoords,
  filterStopsForCoords,
  filterStopsForDuplicate,
  filterToursForCoords,
  filterToursForDuplicate,
  getBoundingBox,
  getBoundingBoxCoords,
  getElementsWithSamePosition,
  getNumStopsOnMap,
  getPolyLines
} from '../../../../services/util/mapUtils';
import useMapControls from '../../../../hooks/useMapControls';


function AddStopsToTourBaseMap(props) {

  const {
    className,
    tours,
    microHubs,
    notAddedStops,
    t,
    displayControls,
    addedStops,
    displayAllTours,
    setDisplayAllTours,
    getPolyLines,
    getStopClusterIcon,
    getStopMarkerAndAddToMcg,
    getTourStopMarkerAndAddToMcg,
    getTourClusterIcon,
    getToAddStopMarkerAndAddToMcg,
    filterToursForCoords,
    filterMicroHubsForCoords,
    filterStopsForCoords,
    displayAllToursLabel,
    getOverLappingMarkerClusterIcon,
  } = props;

  const map = useRef(null);

  const toursWithStopsWithCoords = filterToursForCoords(tours);
  const microHubsWithCoords = filterMicroHubsForCoords(microHubs);
  const notAddedStopsWithCoords = filterStopsForCoords(notAddedStops);
  const addedStopsWithCoords = filterStopsForCoords(addedStops);

  const stopsOnMap = getNumStopsOnMap(toursWithStopsWithCoords, notAddedStopsWithCoords, addedStopsWithCoords);
  const { useCluster, setUseCluster, useDuplicateSpiderfy, setUseDuplicateSpiderfy, highLiteDuplicates, setHighLiteDuplicates } = useMapControls(stopsOnMap);

  const polyLines = getPolyLines(toursWithStopsWithCoords, microHubs);

  const overlappingElementGroups = useDuplicateSpiderfy ? getElementsWithSamePosition(toursWithStopsWithCoords, notAddedStopsWithCoords, addedStopsWithCoords) : [];
  const keysOfOverlappingElements = overlappingElementGroups.map(g => g[0].key);

  const filteredTours = filterToursForDuplicate(toursWithStopsWithCoords, keysOfOverlappingElements);
  const filteredNotAddedStops = filterStopsForDuplicate(notAddedStopsWithCoords, keysOfOverlappingElements);
  const filteredAddedStops = filterStopsForDuplicate(addedStopsWithCoords, keysOfOverlappingElements);


  L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);


  const fitMapBoundsToFG = useCallback(() => {
    const coordinates = getBoundingBoxCoords(polyLines, notAddedStopsWithCoords.concat(addedStopsWithCoords), microHubsWithCoords);
    if (coordinates.length <= 1) return;
    const boundingBox = getBoundingBox(coordinates);
    map.current.leafletElement.fitBounds(boundingBox, { padding: [30, 30] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayAllTours]);

  useEffect(() => {
    fitMapBoundsToFG();
  }, [fitMapBoundsToFG]);

  return (
    <Fragment>
      <Map
        boundsOptions={{ padding: [10, 10] }}
        center={[50, 10.5]}
        className={className}
        gestureHandling
        ref={map}
        zoom={6}
        zoomSnap={0.25}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Fragment>
          {microHubsWithCoords.map((hub, index) => {
            return (
              <MicroHubMarker
                key={index}
                microHub={hub}
              />
            );
          })}
        </Fragment>
        <Fragment>
          {filteredTours.map((tour, index) => (
            <TourMarkerCluster
              getClusterIcon={getTourClusterIcon}
              getTourStopMarkerAndAddToMcg={getTourStopMarkerAndAddToMcg}
              key={index}
              polyline={polyLines[index]}
              stops={tour.stops}
              tour={tour}
              useCluster={useCluster}
            />
          ))}
        </Fragment>
        <Fragment>
          <StopMarkerCluster
            getClusterIcon={getStopClusterIcon}
            getStopMarkerAndAddToMcg={getStopMarkerAndAddToMcg}
            stops={filteredNotAddedStops}
            useCluster={useCluster}
          />
        </Fragment>
        <Fragment>
          <StopMarkerCluster
            getClusterIcon={getStopClusterIcon}
            getStopMarkerAndAddToMcg={getToAddStopMarkerAndAddToMcg}
            stops={filteredAddedStops}
            useCluster={false}
          />
        </Fragment>
        {overlappingElementGroups.map((elementGroup, index) => (
          <OverlappingMarkerCluster
            getClusterIcon={getOverLappingMarkerClusterIcon}
            getStopMarkerAndAddToMcg={getStopMarkerAndAddToMcg}
            getToAddStopMarkerAndAddToMcg={getToAddStopMarkerAndAddToMcg}
            getTourStopMarkerAndAddToMcg={getTourStopMarkerAndAddToMcg}
            highLiteDuplicates={highLiteDuplicates}
            key={index}
            overlappingElements={elementGroup}
          />
        ))}
      </Map>
      {displayControls &&
        <MapControls
          additionalControls={
            <div style={{ marginLeft: '9px' }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={displayAllTours}
                    color="secondary"
                    onChange={(event) => setDisplayAllTours(event.target.checked)}
                    value={displayAllTours}
                  />
                }
                label={displayAllToursLabel ? displayAllToursLabel : t('displayAllTours')}
              />
            </div>
          }
          highLiteDuplicates={highLiteDuplicates}
          setHighLiteDuplicates={setHighLiteDuplicates}
          setUseCluster={setUseCluster}
          setUseDuplicateSpiderfy={setUseDuplicateSpiderfy}
          useCluster={useCluster}
          useDuplicateSpiderfy={useDuplicateSpiderfy}
        />
      }
    </Fragment>
  );
}

AddStopsToTourBaseMap.defaultProps = {
  filterToursForCoords: filterToursForCoords,
  filterStopsForCoords: filterStopsForCoords,
  filterMicroHubsForCoords: filterMicroHubsForCoords,
  getPolyLines: getPolyLines,
  displayControls: true,
};

AddStopsToTourBaseMap.propTypes = {
  addedStops: PropTypes.array.isRequired,
  className: PropTypes.string,
  displayAllTours: PropTypes.bool.isRequired,
  displayAllToursLabel: PropTypes.node,
  displayControls: PropTypes.bool,
  filterMicroHubsForCoords: PropTypes.func,
  filterStopsForCoords: PropTypes.func,
  filterToursForCoords: PropTypes.func,
  getOverLappingMarkerClusterIcon: PropTypes.func.isRequired,
  getPolyLines: PropTypes.func,
  getStopClusterIcon: PropTypes.func.isRequired,
  getStopMarkerAndAddToMcg: PropTypes.func.isRequired,
  getToAddStopMarkerAndAddToMcg: PropTypes.func.isRequired,
  getTourClusterIcon: PropTypes.func.isRequired,
  getTourStopMarkerAndAddToMcg: PropTypes.func.isRequired,
  microHubs: PropTypes.array.isRequired,
  notAddedStops: PropTypes.array.isRequired,
  setDisplayAllTours: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  tours: PropTypes.array.isRequired,
};


export default withTranslation()(AddStopsToTourBaseMap);

