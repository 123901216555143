import React, { Fragment, useEffect, useState } from 'react'
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  Grid,
  Tooltip,
  Typography,
  withStyles
} from '@material-ui/core';
import styles from './styles';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { AddCustomersToTourTemplateMap } from '../../../../../components';
import { Cancel as CancelIcon, Save as SaveIcon } from '@material-ui/icons';
import CustomersAddTable from './CustomersAddTable';
import {
  formatTemplateCapacityConstraint,
  onCloseWithBackdropCurry,
  roundCapacityValues
} from '../../../../../services/util/helperFuncs';
import { NoCapacitiesAvailableWarning } from '../index';
import { displayModes } from '../../../../../services/enums/displayModes';

function AddCustomersDialog(props) {

  const {
    classes,
    t,
    dialogState,
    handleCancel,
    handleClose,
    customers,
    microHubs,
    templates,
    selectedTemplate,
    displayMode
  } = props;
  const [customersToAdd, setCustomersToAdd] = useState([]);
  const [displayAllTemplates, setDisplayAllTemplates] = useState(true);
  const [customersInternal, setCustomersInternal] = useState([]);
  const [autoOrderOnAdd, setAutoOrderOnAdd] = useState(true);
  const [templateCapacities, setTemplateCapacities] = useState(null);

  useEffect(() => {
    const templateStopCustomerIds = displayAllTemplates ? templates.map(t => t.stops.map(s => s.customer.internalCustomerId)).flat() : selectedTemplate.stops.map(s => s.customer.internalCustomerId);
    const customersNotOnTemplates = customers
      .filter(customer => !selectedTemplate || customer.shipper === selectedTemplate.shipper) // Filter customers for right shipper
      .filter(customer => !templateStopCustomerIds.includes(customer.id)); // Filter customers for selected Template and other templates
    setCustomersInternal(customersNotOnTemplates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customers, displayAllTemplates, templates, selectedTemplate]);

  useEffect(() => {
    setTemplateCapacities(selectedTemplate.capacities);
  }, [selectedTemplate]);

  const addOrRemoveCustomer = (customer) => {
    if (customersInternal.findIndex(c => c.id === customer.id) >= 0) {
      const newCustomersToAdd = JSON.parse(JSON.stringify(customersToAdd));
      const index = newCustomersToAdd.findIndex(presentCustomer => presentCustomer.id === customer.id);
      if (index >= 0) {
        newCustomersToAdd.splice(index, 1);
      } else {
        newCustomersToAdd.push(customer);
      }
      setCustomersToAdd(newCustomersToAdd);
      // Update Template capacity
      const customerCapacity = customer.weekDays.filter(wd => wd.dayOfWeek === selectedTemplate.dayOfWeek)[0]?.capacities;
      const factor = index >= 0 ? -1 : 1;
      if (!customerCapacity) {
        setTemplateCapacities(prevState => ({
          ...prevState,
          stopsWithoutCapacities: prevState.stopsWithoutCapacities + factor
        }));
      } else {
        setTemplateCapacities(prevState => (
          {
            ...prevState,
            boxAmount: prevState.boxAmount + customerCapacity.boxAmount * factor,
            weight: prevState.weight + customerCapacity.weight * factor,
            volume: prevState.volume + customerCapacity.volume * factor,
          }
        ))
      }
    }
  };

  const handleOk = () => {
    handleClose(customersToAdd, selectedTemplate, autoOrderOnAdd);
    setCustomersToAdd([]);
  };

  const handleNotOk = () => {
    handleCancel();
    setCustomersToAdd([]);
  };

  return (
    <Dialog
      className={classes.dialog}
      disableEscapeKeyDown
      fullWidth
      maxWidth={'xl'}
      onClose={onCloseWithBackdropCurry(handleCancel)}
      open={dialogState.open}
    >
      <div
        className={classes.headerContainer}
        style={selectedTemplate ? { backgroundColor: selectedTemplate.color } : {}}
      >
        <Typography><strong>{`${t('customerAdd')} ${selectedTemplate ? `${t('toTourTemplate')} ${selectedTemplate.id}` : ''}`}</strong></Typography>
        <Typography><strong>{`${t('tourTemplateName')}: ${selectedTemplate ? selectedTemplate.templateName : '-'}`}</strong></Typography>
        <Typography><strong>{`${t('tourTemplateCustomers')}: ${selectedTemplate?.stops ? selectedTemplate.stops.length + customersToAdd.length : '-'}`}</strong></Typography>
        <Typography><strong>{`${t('tourTemplateApplyAtMin')}: ${selectedTemplate ? formatTemplateCapacityConstraint(selectedTemplate.applyAtMin) : '-'}`}</strong></Typography>
        {!selectedTemplate?.isAutoCutTemplate &&
          <Typography><strong>{`${t('tourTemplateMaxApplicable')}: ${selectedTemplate ? formatTemplateCapacityConstraint(selectedTemplate.maxApplicable) : '-'}`}</strong></Typography>
        }
        {templateCapacities &&
          <Fragment>
            <Typography>
              <strong>{`${t('boxAmount')}: ${roundCapacityValues(templateCapacities.boxAmount)}`}</strong>&nbsp;
              {templateCapacities.stopsWithoutCapacities > 0 &&
                <NoCapacitiesAvailableWarning stopsWithoutCapacities={templateCapacities.stopsWithoutCapacities} />
              }
            </Typography>
            <Typography>
              <strong>{`${t('weight')}: ${roundCapacityValues(templateCapacities.weight)}`}</strong>&nbsp;
              {templateCapacities.stopsWithoutCapacities > 0 &&
                <NoCapacitiesAvailableWarning
                  stopsWithoutCapacities={roundCapacityValues(templateCapacities.stopsWithoutCapacities)} />
              }
            </Typography>
            <Typography>
              <strong>{`${t('volume')}: ${roundCapacityValues(templateCapacities.volume)}`}</strong>&nbsp;
              {templateCapacities.stopsWithoutCapacities > 0 &&
                <NoCapacitiesAvailableWarning stopsWithoutCapacities={templateCapacities.stopsWithoutCapacities} />
              }
            </Typography>
          </Fragment>
        }
      </div>
      <DialogContent>
        <Grid
          container
          spacing={1}
        >
          <Grid
            item
            lg={7}
            sm={12}
            xl={7}
            xs={12}
          >
            <AddCustomersToTourTemplateMap
              addedCustomers={customersToAdd}
              className={classes.map}
              customers={customersInternal ? customersInternal.filter(customer => customersToAdd.findIndex(c => c.id === customer.id) < 0) : []}
              displayAllTemplates={displayAllTemplates}
              microHubs={microHubs}
              onSelectCustomer={addOrRemoveCustomer}
              selectedTemplate={selectedTemplate}
              setDisplayAllTemplates={setDisplayAllTemplates}
              templates={displayAllTemplates ? templates : [selectedTemplate]}
            />
          </Grid>
          <Grid
            item
            lg={5}
            sm={12}
            xl={5}
            xs={12}
          >
            <CustomersAddTable
              addOrRemoveCustomer={addOrRemoveCustomer}
              className={classes.table}
              customers={customersInternal}
              customersToAdd={customersToAdd}
              selectedTemplate={selectedTemplate}
              displayMode={displayMode}
            />
          </Grid>
        </Grid>

      </DialogContent>
      <DialogActions>
        <FormControl>
          <Tooltip title={t('autoOrderOnAddTooltip')}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={autoOrderOnAdd}
                  color="secondary"
                  onChange={(event) => setAutoOrderOnAdd(event.target.checked)}
                  value={autoOrderOnAdd}
                />
              }
              label={t('autoOrderOnAdd')}
            />
          </Tooltip>
        </FormControl>
        <Button
          color="default"
          onClick={handleNotOk}
        >
          <CancelIcon />&nbsp;{t('dialogCancel')}
        </Button>
        <Button
          color="primary"
          disabled={!customersToAdd || customersToAdd.length === 0}
          onClick={handleOk}
        >
          <SaveIcon />&nbsp;{t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

AddCustomersDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  customers: PropTypes.array.isRequired,
  dialogState: PropTypes.object.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  microHubs: PropTypes.array.isRequired,
  selectedTemplate: PropTypes.object,
  t: PropTypes.func.isRequired,
  templates: PropTypes.array.isRequired,
};

export default compose(withStyles(styles), withTranslation())(AddCustomersDialog);
