import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableHead, TableRow, withStyles } from '@material-ui/core';
import styles from './styles';
import classNames from 'classnames';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import DefaultConfig from './config';
import Columns from './columns';
import useTableConfig from 'hooks/useTableConfig';
import { getTableKey } from 'services/util/tableConfigHelper';
import { TableKind } from 'services/enums/TableKind';
import { Portlet, PortletContent, TableCellWithConfig, TableSettings, TextPlaceholderWrapper } from 'components';
import { displayModes } from 'services/enums/displayModes';

function CustomersAddTable(props) {

  const {
    classes,
    className,
    t,
    customers,
    addOrRemoveCustomer,
    customersToAdd,
    selectedTemplate,
    displayMode,
    customerIdToTemplateStopDict
  } = props;

  const [sortedCustomers, setSortedCustomers] = useState([]);

  const defaultConfig = useTableConfig(DefaultConfig, displayMode, getTableKey(TableKind.CustomerAddTable, displayMode))

  const rootClassName = classNames(classes.root, className);

  useEffect(() => {
    const pickups = [];
    const deliveries = [];
    const others = [];
    // group customers  
    customers.forEach(customer => {
      if (customer.templateStop) {
        const templateStop = customer.templateStop;
        if (templateStop.pickupDeliveryPickup?.length > 0) {
          pickups.push(customer);
        } else if (templateStop.pickupDeliveryDelivery?.length > 0) {
          deliveries.push(customer);
        } else {
          others.push(customer);
        }
      }
    });
    // sort pickup and delivery customers  
    const sortedCustomerList = [];
    pickups.forEach(pickup => {
      sortedCustomerList.push(pickup);
      const relatedDeliveries = deliveries.filter(delivery =>
        delivery.templateStop.pickupDeliveryDelivery.some(deliveryItem =>
          deliveryItem.pickupStopId === pickup.templateStop.id));
      sortedCustomerList.push(...relatedDeliveries);
    });
    // find deliveries that aren't in the sortedCustomerList
    const remainingDeliveries = deliveries.filter(delivery =>
      !sortedCustomerList.some(sortedCustomer => sortedCustomer.id === delivery.id)
    );
    sortedCustomerList.push(...remainingDeliveries);
    // add customers without templateStop  
    sortedCustomerList.push(...others);
    setSortedCustomers(sortedCustomerList);
  }, [customers]);

  return (
    <TextPlaceholderWrapper
      active={!customers?.length}
      text={t('noCustomersAvailable')}
    >
      <div style={{ position: 'relative' }}>
        <TableSettings
          updateConfig={defaultConfig.updateConfig}
          tableConfig={defaultConfig.config}
          columns={Columns}
          buttonOptic
        />
        <Portlet className={rootClassName}>
          <PortletContent noPadding>
            <Table
              size={'small'}
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  {defaultConfig.columnConfigs.map(columnConfig => (
                    <TableCellWithConfig
                      key={columnConfig.order}
                      config={columnConfig}
                    >
                      {Columns[columnConfig.columnName].header({ t })}
                    </TableCellWithConfig>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedTemplate && sortedCustomers.map((customer, index) => {
                  const customerCapacities = customer.weekDays.filter(wd => wd.dayOfWeek === selectedTemplate.dayOfWeek)[0]?.capacities;
                  return (
                    <TableRow
                      className={classNames(classes.tableRow, 'cursorPointer')}
                      hover
                      key={index}
                      onClick={() => addOrRemoveCustomer(customer)}
                      selected={customersToAdd.some(c => c.id === customer.id)}
                    >
                      {defaultConfig.columnConfigs.map(columnConfig => (
                        <TableCellWithConfig
                          key={columnConfig.order}
                          config={columnConfig}
                        >
                          {Columns[columnConfig.columnName].body({
                            customer, customerCapacities, customerIdToTemplateStopDict, t
                          })}
                        </TableCellWithConfig>
                      ))}
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </PortletContent>
        </Portlet>
      </div>
    </TextPlaceholderWrapper>
  );
}

CustomersAddTable.propTypes = {
  addOrRemoveCustomer: PropTypes.func.isRequired,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  customers: PropTypes.array.isRequired,
  customersToAdd: PropTypes.array.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  selectedTemplate: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  customerIdToTemplateStopDict: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withTranslation())(CustomersAddTable);
