import { compose } from 'recompose';
import { Typography, withStyles } from '@material-ui/core';
import { Place } from '@material-ui/icons';
import { withTranslation } from 'react-i18next';
import styles from './styles'
import classNames from 'classnames';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { getTemplateStopTypeString, TemplateStopType } from '../../../../services/enums/TemplateStopType';


function TemplateStopLegend(props) {

  const {
    classes,
    className,
    t,
  } = props;

  const rootClassName = classNames(classes.root, className);


  return (
    <Fragment>
      <Typography
        className={rootClassName}
        variant="body1"
      >
        <Fragment>
          <Place
            className={classNames(classes.materialIcon, classes.iconDelivery)}
          />
          {t(getTemplateStopTypeString(TemplateStopType.Delivery))}&nbsp;

          <Place
            className={classNames(classes.materialIcon, classes.iconPickup)}
          />
          {t(getTemplateStopTypeString(TemplateStopType.Pickup))}&nbsp;
        </Fragment>
      </Typography>
    </Fragment>
  );
}

TemplateStopLegend.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};


export default compose(withStyles(styles), withTranslation())(TemplateStopLegend);

