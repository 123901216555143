import React, { Fragment, useEffect, useState } from 'react'
import { Button, Grid, Switch, withStyles } from '@material-ui/core';
import styles from './styles'
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DragDropContext } from 'react-beautiful-dnd';
import {
  Add as AddIcon,
  ControlPointDuplicate as DuplicateIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  PlaceOutlined as MarkerIcon,
  PlaylistAdd as AddMultipleIcon
} from '@material-ui/icons';
import { TourTemplateMap } from '../../index';
import {
  AddCustomersDialog,
  CreateTourTemplateDialog,
  EditTourTemplateDialog,
  TemplateStopTable,
  TemplateTable,
  AddCustomersDialogTemplateStop
} from './components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { cloneDeep } from 'lodash';
import { getDayNumberByDayOfWeek } from 'services/enums/dayOfWeekHelper';
import { DndHelperElementTypes, DndHelperService } from 'services/dndHelper/dndHelperService';
import tourTemplatesService from 'services/backend/tourTemplatesService';
import { presetsService } from 'services/presets/presetService';
import { displayModes } from 'services/enums/displayModes';

function TourTemplatesView(props) {

  const {
    classes,
    className,
    t,
    tourTemplates,
    setTourTemplates,
    microHubs,
    customers,
    filter,
    carrierWithMicroHubs,
    reloadTourTemplates,
    vehicles,
    shipperOptions,
    displayMode,
    templateStopMode,
    templateStops
  } = props;

  const rootClassName = classNames(classes.root, className);

  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [displayCustomers, setDisplayCustomers] = useState(true);
  const [createTourTemplateDialogState, setCreateTourTemplateDialogState] = useState({ open: false });
  const [editTourTemplateDialogState, setEditTourTemplateDialogState] = useState({ open: false });
  const [addCustomersToTemplateDialogState, setAddCustomersToTemplateDialogState] = useState({ open: false });
  const [customersToDisplayOnMap, setCustomersToDisplayOnMap] = useState([]);
  const [showOnlySelectedTemplate, setShowOnlySelectedTemplate] = useState(false);
  const [templateTableCapacityMode, setTemplateTableCapacityMode] = useState(false);
  const [templateStopTableCapacityMode, setTemplateStopTableCapacityMode] = useState(false);
  const [templatePreset, setTemplatePreset] = useState({});
  const [customerIdToTemplateStopDict, setCustomerIdToTemplateStopDict] = useState({});

  useEffect(() => {
    const newTemplates = cloneDeep(tourTemplates);
    let dirty = false;
    let customerIdToCustomerDict = {};
    let customerIdToTemplateStopDict = {};

    // create customer dict
    customerIdToCustomerDict = customers.reduce((acc, customer) => {
      const capacities = customer.weekDays.filter(wd => wd.dayOfWeek === getDayNumberByDayOfWeek(filter.dayOfWeek))[0]?.capacities;
      const streetAndNumber = customer.address.streetAndNumber;
      const zipcode = customer.address.zipcode;
      acc[customer.id] = { capacities: capacities, streetAndNumber: streetAndNumber, zipcode: zipcode }
      return acc;
    }, {});

    // create templateStop dict
    if (templateStopMode) {
      customerIdToTemplateStopDict = templateStops.reduce((acc, templateStop) => {
        acc[templateStop.internalCustomerId] = templateStop;
        return acc;
      }, {});
      setCustomerIdToTemplateStopDict(customerIdToTemplateStopDict)
    }

    // Initialize templates with empty capacity objects
    newTemplates.forEach((template) => {
      // If Template is new -> add capacities
      if (!template.capacities) {
        template.capacities = {
          boxAmount: 0,
          weight: 0,
          volume: 0,
          stopsWithoutCapacities: 0
        };
        dirty = true;
      }

      // Fill templates with values from dictionaries
      if (template.stops.some(s => s.capacities === undefined)) {
        template.stops.forEach(s => {
          // for each stop find corresponding customer and add capacities
          s.capacities = customerIdToCustomerDict[s.customer.internalCustomerId]?.capacities;
          s.streetAndNumber = customerIdToCustomerDict[s.customer.internalCustomerId]?.streetAndNumber;
          s.zipcode = customerIdToCustomerDict[s.customer.internalCustomerId]?.zipcode;
          if (!s.capacities) {
            template.capacities.stopsWithoutCapacities++;
          } else {
            template.capacities.boxAmount += s.capacities.boxAmount;
            template.capacities.weight += s.capacities.weight;
            template.capacities.volume += s.capacities.volume;
          }

          //for each stop find corresponding template stop
          s.templateStop = customerIdToTemplateStopDict[s.customer.internalCustomerId];
        });
        dirty = true;
      }
    });

    // add template stops to customer information
    if (customers.length > 0) {
      customers.forEach(c => {
        c.templateStop = customerIdToTemplateStopDict[c.id];
      })
    }

    // only set new tourTemplates when changes are required to prevent to many rerenders
    if (dirty) setTourTemplates(newTemplates);
  }, [customers, tourTemplates, templateStops, setTourTemplates, filter.dayOfWeek, filter.shipperName, filter.microHubName, filter.carrierName, templateStopMode, setCustomerIdToTemplateStopDict]);

  useEffect(() => {
    let newTemplatePreset = presetsService.getTourTemplatePresetWithCarrierMicroHubDayOfWeekAndShipper(filter.carrierName, filter.microHubName, filter.dayOfWeek, filter.shipperName);
    if (templateStopMode) {
      newTemplatePreset.isAutoCutTemplate = false;
    }
    setTemplatePreset(newTemplatePreset)
  }, [filter.dayOfWeek, filter.shipperName, filter.microHubName, filter.carrierName, templateStopMode]);

  useEffect(() => {
    setSelectedTemplate(prevState => {
      if (prevState === null) return prevState;
      const selectedTemplateIndex = tourTemplates.findIndex(t => t.id === prevState.id);
      return selectedTemplateIndex >= 0 ? tourTemplates[selectedTemplateIndex] : null;
    })
  }, [tourTemplates]);

  useEffect(() => {
    const templateStopCustomerIds = showOnlySelectedTemplate ? selectedTemplate?.stops?.map(s => s.customer.internalCustomerId) || [] :
      tourTemplates.map(t => t.stops.map(s => s.customer.internalCustomerId)).flat();
    const customersNotOnTemplates = customers.filter(customer => !templateStopCustomerIds.includes(customer.id));
    setCustomersToDisplayOnMap(customersNotOnTemplates);
  }, [customers, tourTemplates, showOnlySelectedTemplate, selectedTemplate]);

  const onSelectStopOnMapFromTemplate = (stop) => {
    const template = tourTemplates.filter(tour => tour.stops.map(s => s.id).includes(stop.id))[0];
    setSelectedTemplate(template);
  };

  const onDragEnd = (event) => {
    if (!event.destination) {
      return;
    }

    if (event.destination === event.source) {
      return;
    }

    const source = DndHelperService.parseUniqueId(event.source.droppableId);
    const destination = DndHelperService.parseUniqueId(event.destination.droppableId);
    const element = DndHelperService.parseUniqueId(event.draggableId);

    if (element.type === DndHelperElementTypes.Tour) {
      // Templat was moved in template table
      onReorderTemplates(event.source.index, event.destination.index);
    } else if (element.type === DndHelperElementTypes.Stop && source.tourId === destination.tourId) {
      // Template stop was moved on same template
      onReorderStopsOnTemplate(element.stopId, element.tourId, event.source.index, event.destination.index);
    } else if (element.type === DndHelperElementTypes.Stop && source.tourId !== destination.tourId) {
      // Template stop was moved to another template
      onMoveStopToOtherTemplate(element.stopId, source.tourId, destination.tourId);
    } else {
      console.warn('Unknown or unhandled Drag & Drop event', event);
    }
  };

  const onReorderTemplates = async (sourceIndex, destinationIndex) => {
    if (sourceIndex === destinationIndex) return;
    const reorderedTemplates = DndHelperService.reorder(tourTemplates, sourceIndex, destinationIndex);
    reorderedTemplates.forEach((template, index) => {
      template.tourTemplateNumber = index + 1
    })
    const reorderedTemplateIds = reorderedTemplates.map(x => x.id);
    setTourTemplates(reorderedTemplates);
    try {
      await tourTemplatesService.reorderTourTemplates(filter.carrierName, filter.microHubName, getDayNumberByDayOfWeek(filter.dayOfWeek), reorderedTemplateIds);
    } catch (error) {
      alert(`${t('errorReorderingTourTemplates')}, ${error}`);
      reloadTourTemplates();
    }
  };

  const onReorderStopsOnTemplate = async (stopId, templateId, sourceIndex, destinationIndex) => {
    if (sourceIndex === destinationIndex) return;
    const reorderedStops = DndHelperService.reorder(selectedTemplate.stops, sourceIndex, destinationIndex);
    reorderedStops.forEach((stop, index) => {
      stop.stopNumber = index + 1
    });
    const reorderedStopIds = reorderedStops.map(x => x.id);
    const newTemplates = JSON.parse(JSON.stringify(tourTemplates));
    const templateIndex = newTemplates.findIndex(t => t.id === templateId);
    if (templateIndex >= 0) {
      newTemplates[templateIndex].stops = reorderedStops;
      setTourTemplates(newTemplates);
    }
    try {
      await tourTemplatesService.reorderStopsOnTourTemplates(templateId, reorderedStopIds);
    } catch (error) {
      alert(`${t('errorReorderingStopsOfTourTemplate')}, ${error}`);
      reloadTourTemplates();
    }
  };

  const onMoveStopToOtherTemplate = async (stopId, sourceTemplateId, destinationTemplateId) => {
    if (sourceTemplateId === destinationTemplateId) return;
    try {
      await tourTemplatesService.moveStopToTemplate(stopId, destinationTemplateId);
      reloadTourTemplates(false);
    } catch (error) {
      alert(`${t('errorMovingStopToOtherTourTemplate')}, ${error}`);
      reloadTourTemplates();
    }
  };

  const onCreateTemplate = async (template) => {
    setCreateTourTemplateDialogState(prevState => ({ ...prevState, open: false }));
    try {
      const dayOfWeekOfTemplate = template.dayOfWeek;
      template.dayOfWeek = getDayNumberByDayOfWeek(template.dayOfWeek); // Change day of week to match backend enum!
      const newTemplate = await tourTemplatesService.createTourTemplate(template);
      if (filter.dayOfWeek !== dayOfWeekOfTemplate) return; //do not update UI if template is not active on current day
      if ((!templateStopMode && filter.microHubName !== newTemplate.microHub) || (templateStopMode && filter.microHubName && filter.microHubName !== newTemplate.microHub)) return; //do not update UI if template is not for current microHub
      const newTourTemplates = JSON.parse(JSON.stringify(tourTemplates));
      newTourTemplates.push(newTemplate);
      setTourTemplates(newTourTemplates);
    } catch (error) {
      alert(`${t('errorCreatingTourTemplate')}, ${error}`);
      reloadTourTemplates();
    }
  };

  const onDeleteTemplate = async (template) => {
    try {
      // manually update for faster UI response time
      const newTourTemplates = JSON.parse(JSON.stringify(tourTemplates));
      const index = newTourTemplates.findIndex(x => x.id === template.id);
      if (index >= 0) {
        newTourTemplates.splice(index, 1);
        setTourTemplates(newTourTemplates)
      }
      // call backend and reload to get right priorities for all loaded templates
      await tourTemplatesService.deleteTourTemplate(template.id);
      reloadTourTemplates(false);
    } catch (error) {
      alert(`${t('errorDeletingTourTemplate')}, ${error}`);
      reloadTourTemplates();
    }
  };

  const onEditTemplate = async (template) => {
    setEditTourTemplateDialogState({ ...editTourTemplateDialogState, open: false });
    try {
      // manually update for faster UI response time
      const newTourTemplates = JSON.parse(JSON.stringify(tourTemplates));
      const index = newTourTemplates.findIndex(x => x.id === template.id);
      if (index >= 0) {
        newTourTemplates[index] = template;
        setTourTemplates(newTourTemplates)
      }
      await tourTemplatesService.updateTourTemplate(template.id, template);
    } catch (error) {
      alert(`${t('errorUpdatingTourTemplate')}, ${error}`);
      reloadTourTemplates();
    }
  };

  const onAddCustomersToTemplate = async (customers, template, autoOrderOnAdd) => {
    setAddCustomersToTemplateDialogState({ ...addCustomersToTemplateDialogState, open: false });
    const stopsToAdd = customers.map(customer => ({
      internalCustomerId: customer.id,
      externalCustomerId: customer.externalCustomerId,
      lastName: customer.lastName,
      firstName: customer.firstName,
      shipper: customer.shipper,
      latitude: customer.address.latitude,
      longitude: customer.address.longitude,
    }));
    try {
      let updatedTemplate;
      if (autoOrderOnAdd) {
        updatedTemplate = await tourTemplatesService.addCustomersToTourTemplate(template.id, stopsToAdd);
      } else {
        updatedTemplate = await tourTemplatesService.addCustomersToTourTemplateInClickOrder(template.id, stopsToAdd);
      }
      const newTourTemplates = JSON.parse(JSON.stringify(tourTemplates));
      const index = newTourTemplates.findIndex(x => x.id === template.id);
      if (index >= 0) newTourTemplates[index] = updatedTemplate;
      setTourTemplates(newTourTemplates);
    } catch (error) {
      alert(`${t('errorAddingCustomersToTourTemplate')}, ${error}`);
      reloadTourTemplates();
    }
  };

  const onDeleteStopFromTemplate = async (stopId, template) => {
    try {
      const updatedTemplate = await tourTemplatesService.deleteTourTemplateStopById(stopId);
      const newTourTemplates = JSON.parse(JSON.stringify(tourTemplates));
      const index = newTourTemplates.findIndex(x => x.id === template.id);
      if (index >= 0) newTourTemplates[index] = updatedTemplate;
      setTourTemplates(newTourTemplates);
    } catch (error) {
      alert(`${t('errorDeletingStopFromTourTemplate')}, ${error}`);
      reloadTourTemplates();
    }
  };

  const onDuplicateTemplate = async (template) => {
    try {
      const newTemplate = await tourTemplatesService.duplicateTourTemplate(template.id);
      const newTourTemplates = JSON.parse(JSON.stringify(tourTemplates));
      newTourTemplates.push(newTemplate);
      setSelectedTemplate(newTemplate);
      setTourTemplates(newTourTemplates);
    } catch (e) {
      alert(`${t('errorDuplicatingTourTemplate')}, ${e}`);
      reloadTourTemplates();
    }
  };

  return (
    <Fragment>
      <FormControlLabel
        className={classes.displayCustomersCheckbox}
        control={
          <Checkbox
            checked={displayCustomers}
            color="secondary"
            onChange={(event) => setDisplayCustomers(event.target.checked)}
            value={displayCustomers}
          />
        }
        label={
          <Fragment>
            <MarkerIcon
              className={classes.customerMarkerIcon}
            />
            {t('displayCustomers')}
          </Fragment>
        }
      />
      <FormControlLabel
        className={classes.displayCustomersCheckbox}
        control={
          <Checkbox
            checked={showOnlySelectedTemplate}
            color="secondary"
            onChange={(event) => setShowOnlySelectedTemplate(event.target.checked)}
            value={showOnlySelectedTemplate}
          />
        }
        label={t('showOnlySelectedTourTemplate')}
      />
      <Grid
        className={rootClassName}
        container
        spacing={1}
      >
        <Grid
          item
          lg={12}
          sm={12}
          xl={6}
          xs={12}
        >
          <TourTemplateMap
            className={classes.gridItem}
            customers={customersToDisplayOnMap}
            displayControls
            displayCustomers={displayCustomers}
            displayMicroHubs
            microHubs={microHubs.filter(hub => hub.name === filter.microHubName)}
            onSelectStop={onSelectStopOnMapFromTemplate}
            templateTours={showOnlySelectedTemplate ? selectedTemplate ? [selectedTemplate] : [] : tourTemplates}
          />
        </Grid>
        <Grid
          item
          lg={12}
          sm={12}
          xl={6}
          xs={12}
        >
          <div
            className={classes.gridItem}
          >
            <DragDropContext
              onDragEnd={onDragEnd}
            >
              <FormControlLabel
                className={templateStopMode ? classes.hiddenForTemplateStops : {}}
                control={
                  <Switch
                    checked={templateTableCapacityMode}
                    color="secondary"
                    onChange={e => setTemplateTableCapacityMode(e.target.checked)}
                    value={templateTableCapacityMode}
                  />
                }
                label={t('capacityMode')}
              />
              <TemplateTable
                capacityMode={templateTableCapacityMode}
                className={classes.templateTable}
                filter={filter}
                displayMode={displayMode}
                selectedDayOfWeek={filter.dayOfWeek}
                selectedTemplate={selectedTemplate}
                selectTemplate={setSelectedTemplate}
                templates={tourTemplates}
                templateStopMode={templateStopMode}
              />
              <Button
                className={classes.button}
                color="secondary"
                onClick={() => setCreateTourTemplateDialogState({ ...createTourTemplateDialogState, open: true })}
                size="small"
                startIcon={<AddIcon />}
                variant="contained"
              >
                {t('createTemplate')}
              </Button>
              <br />
              {selectedTemplate &&
                <Fragment>
                  <FormControlLabel
                    className={templateStopMode ? classes.hiddenForTemplateStops : {}}
                    control={
                      <Switch
                        checked={templateStopTableCapacityMode}
                        color="secondary"
                        name="stopTableCapacityMode"
                        onChange={e => setTemplateStopTableCapacityMode(e.target.checked)}
                        value={templateStopTableCapacityMode}
                      />
                    }
                    label={t('capacityMode')}
                  />
                  <TemplateStopTable
                    capacityMode={templateStopTableCapacityMode}
                    className={classes.templateTable}
                    deleteStopFromTemplate={(stopId) => onDeleteStopFromTemplate(stopId, selectedTemplate)}
                    displayMode={displayMode}
                    template={selectedTemplate}
                    templateStopMode={templateStopMode}
                  />
                  <Button
                    className={classes.button}
                    color="primary"
                    onClick={() => setAddCustomersToTemplateDialogState({
                      ...addCustomersToTemplateDialogState,
                      open: true
                    })}
                    size="small"
                    title={t('addCustomersToTemplate')}
                    variant="contained"
                  >
                    <AddMultipleIcon />
                  </Button>
                  <Button
                    className={classes.button}
                    color="primary"
                    onClick={() => setEditTourTemplateDialogState({ ...editTourTemplateDialogState, open: true })}
                    size="small"
                    title={t('editTourTemplate')}
                    variant="contained"
                  >
                    <EditIcon />
                  </Button>
                  <Button
                    className={classes.button}
                    color="primary"
                    onClick={() => onDuplicateTemplate(selectedTemplate)}
                    size="small"
                    title={t('duplicateTemplate')}
                    variant="contained"
                  >
                    <DuplicateIcon />
                  </Button>
                  <Button
                    className={classNames(classes.button, classes.redButton)}
                    onClick={() => onDeleteTemplate(selectedTemplate)}
                    size="small"
                    variant="contained"
                  >
                    <DeleteIcon />&nbsp;{t('deleteTemplate')}
                  </Button>
                  <EditTourTemplateDialog
                    dialogState={editTourTemplateDialogState}
                    handleCancel={() => setEditTourTemplateDialogState({ ...editTourTemplateDialogState, open: false })}
                    handleClose={onEditTemplate}
                    templateToEdit={selectedTemplate}
                    vehicles={vehicles}
                    templateStopMode={templateStopMode}
                  />
                  {templateStopMode ?
                    <AddCustomersDialogTemplateStop
                      customers={customers.filter(customer => customer.templateStop != null)}
                      dialogState={addCustomersToTemplateDialogState}
                      handleCancel={() => setAddCustomersToTemplateDialogState({
                        ...addCustomersToTemplateDialogState,
                        open: false
                      })}
                      handleClose={onAddCustomersToTemplate}
                      microHubs={microHubs}
                      selectedTemplate={selectedTemplate}
                      displayMode={displayMode}
                      customerIdToTemplateStopDict={customerIdToTemplateStopDict}
                      templates={tourTemplates}
                    /> :
                    <AddCustomersDialog
                      customers={customers}
                      dialogState={addCustomersToTemplateDialogState}
                      handleCancel={() => setAddCustomersToTemplateDialogState({
                        ...addCustomersToTemplateDialogState,
                        open: false
                      })}
                      handleClose={onAddCustomersToTemplate}
                      microHubs={microHubs}
                      selectedTemplate={selectedTemplate}
                      templates={tourTemplates}
                      displayMode={displayMode}
                      customerIdToTemplateStopDict={customerIdToTemplateStopDict}
                    />}
                </Fragment>
              }
            </DragDropContext>
          </div>
        </Grid>
      </Grid>
      <CreateTourTemplateDialog
        carrierWithMicroHubs={carrierWithMicroHubs}
        dialogState={createTourTemplateDialogState}
        handleCancel={() => setCreateTourTemplateDialogState({ createTourTemplateDialogState, open: false })}
        handleClose={onCreateTemplate}
        microHubs={microHubs}
        shipperOptions={shipperOptions}
        templatePreset={templatePreset}
        vehicles={vehicles}
        templateStopMode={templateStopMode}
      />
    </Fragment>
  );
}


TourTemplatesView.propTypes = {
  carrierWithMicroHubs: PropTypes.object,
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  customers: PropTypes.array.isRequired,
  displayMode: PropTypes.oneOf(Object.values(displayModes)).isRequired,
  filter: PropTypes.object.isRequired,
  microHubs: PropTypes.array.isRequired,
  reloadTourTemplates: PropTypes.func.isRequired,
  setTourTemplates: PropTypes.func.isRequired,
  shipperOptions: PropTypes.array.isRequired,
  t: PropTypes.func.isRequired,
  tourTemplates: PropTypes.array.isRequired,
  vehicles: PropTypes.array.isRequired,
  templateStopMode: PropTypes.bool,
  templateStops: PropTypes.array,
};

export default compose(withStyles(styles), withTranslation())(TourTemplatesView);
