import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { InfoTooltip } from '../../../index';
import { filterTemplatesForCoords, getPolyLinesFromTemplates } from 'services/util/mapUtils';
import { customerIconColor, IconService } from 'services/icon/iconService'
import { useTranslation } from 'react-i18next';
import { MarkerCreateFuncs, OverlappingMarkerCreateFuncs } from '../../MapComponents'
import AddStopToTemplateStopTourBaseMap from 'components/Maps/BaseMaps/AddStopToTemplateStopTourBaseMap';

function AddCustomersToTemplateStopTourMap(props) {
  const { className, microHubs, customers, onSelectCustomer, selectedTemplate, addedCustomers, customerIdToTemplateStopDict, displayAllTemplates, setDisplayAllTemplates, templates } = props;

  const { t } = useTranslation();

  const [hoveredCustomer, setHoveredCustomer] = useState(null);

  const highlightTemplateStopRelations = useCallback((customer) => {
    // we don't want a rerender, if the customer is the same
    if (hoveredCustomer === null || customer.id !== hoveredCustomer.id) {
      setHoveredCustomer(customer);
    }
  }, [hoveredCustomer]);

  const hideTemplateStopRelations = useCallback(() => {
    setHoveredCustomer(null);
  }, []);

  const allToursLabel = <Fragment>
    <InfoTooltip descriptionKey={'80'}>
      <Typography>
        {t('displayAllTemplates')}
      </Typography>
    </InfoTooltip>
  </Fragment>;

  return (
    <AddStopToTemplateStopTourBaseMap
      addedStops={addedCustomers}
      className={className}
      displayAllTours={displayAllTemplates}
      displayAllToursLabel={allToursLabel}
      setDisplayAllTours={setDisplayAllTemplates}
      displayControls
      filterToursForCoords={filterTemplatesForCoords}
      getOverLappingMarkerClusterIcon={(overlappingElements, highlightDuplicates) => OverlappingMarkerCreateFuncs.getForAddCustomerToTemplateMap(overlappingElements, highlightDuplicates, () => {
        return selectedTemplate ? selectedTemplate.color : customerIconColor
      })}
      getPolyLines={getPolyLinesFromTemplates}
      getStopMarkerAndAddToMcg={(customer, mcg, t, color) => MarkerCreateFuncs.getCustomerMarker(customer, mcg, t, onSelectCustomer, color, highlightTemplateStopRelations, hideTemplateStopRelations)}
      getToAddStopMarkerAndAddToMcg={(customer, mcg, t) => MarkerCreateFuncs.getCustomerMarker(customer, mcg, t, onSelectCustomer, selectedTemplate ? selectedTemplate.color : customerIconColor, highlightTemplateStopRelations, hideTemplateStopRelations)}
      getTourClusterIcon={(tour) => IconService.getMarkerClusterIcon(tour.color, tour.id)}
      getTourStopMarkerAndAddToMcg={(template, stop, mcg, t) => MarkerCreateFuncs.getTemplateStopMarker(template, stop, mcg, t)}
      microHubs={microHubs}
      notAddedStops={customers}
      tours={templates}
      customerIdToTemplateStopDict={customerIdToTemplateStopDict}
      hoveredCustomer={hoveredCustomer}
    />
  );
}


AddCustomersToTemplateStopTourMap.propTypes = {
  addedCustomers: PropTypes.array.isRequired,
  className: PropTypes.string,
  customers: PropTypes.array.isRequired,
  microHubs: PropTypes.array.isRequired,
  onSelectCustomer: PropTypes.func.isRequired,
  selectedTemplate: PropTypes.object.isRequired,
  customerIdToTemplateStopDict: PropTypes.object.isRequired,
  displayAllTemplates: PropTypes.bool,
  setDisplayAllTemplates: PropTypes.func,
  templates: PropTypes.array.isRequired
};


export default AddCustomersToTemplateStopTourMap;

